import React, { Component } from "react";
import {
  getURL,
  validateImage,
  getBase64,
  getDataToken,
} from "../../assets/scripts/Main";
import CenterContainer from "../../components/CenterContainer";
import axios from "axios";
import { Alert, LinearProgress } from "@mui/material";

export default class Center extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseUrlPhoto: "",
      aircraft: {},
      search: false,
      registration_code: "",
      incorrectInfos: "",
      incorrectInfosDescriptions: "",
      error: false,
      alert: false,
      alertMsg: "",
      loading: false,
    };
  }

  handleFileInputChange = async (e) => {
    let { file } = this.state;
    file = e.target.files[0];
    let baseURL = await getBase64(file);
    let validate = await validateImage(baseURL, 1);
    if (!validate.success) {
      this.setState({
        error: true,
        alert: false,
        alertMsg: validate.msg,
        baseUrlPhoto: "",
      });
    } else if (validate.msg) {
      this.setState({
        error: false,
        alert: true,
        alertMsg: validate.msg,
        baseUrlPhoto: baseURL,
      });
    } else {
      this.setState({
        error: false,
        alert: false,
        alertMsg: "",
        baseUrlPhoto: baseURL,
      });
    }
  };

  handleChengeRegistrationCode = (event) => {
    this.setState({ registration_code: event.target.value });
  };
  handleSearchAircraft = () => {
    var registration = document.getElementById("registrationCode");
    var registration_code = registration ? registration.value : "";

    if (registration_code) {
      this.setState({
        loading: true,
        search: true,
        registration_code: registration_code,
      });
      axios
        .get(getURL("API") + "/aircraft/registration/" + registration_code)
        .then((response) => response.data)
        .then((data) => {
          this.setState({
            loading: false,
            aircraft: data,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            aircraft: {},
          });
        });
    }
  };

  sendPhoto = () => {
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("registration_code", this.state.registration_code);
    formData.append("photo", this.state.photo);

    axios
      .request({
        method: "POST",

        url: getURL("API") + "/photo/sendPhoto/",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          imageBase64: this.state.baseUrlPhoto,
          registration_code: this.state.registration_code,
          incorret_infos: this.state.incorrectInfosDescriptions,
          aircraft_id: this.state.aircraft?.aircraft_id,
        },
      })
      .then((data) => {
        document.location = "./foto/" + data.data.msg;
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
          alertMsg:
            "Ocorreu um erro no envio da foto. Tente novamente mais tarde.",
          baseUrlPhoto: "",
        });
      });
  };

  render() {
    return (
      <>
        <CenterContainer componentclass="noRight">
          <div id="sendPhoto" className="profileContents">
            <div className="defaultBox photo">
              <h4>Enviar foto</h4>
              <div
                className="thumb"
                style={{
                  backgroundImage: "url(" + this.state.baseUrlPhoto + ")",
                }}
              >
                preview
              </div>
              <label for="files" className="sendPhotoBtn">
                Selecionar Imagem
              </label>
              <input
                id="files"
                style={{ display: "none" }}
                type="file"
                onChange={this.handleFileInputChange}
              ></input>
              {(this.state.error || this.state.alert) && (
                <div className="alert">
                  <Alert
                    severity={this.state.error ? "error" : "info"}
                    variant="filled"
                  >
                    {this.state.alertMsg}
                  </Alert>
                </div>
              )}
            </div>

            <div className="defaultBox">
              <h4>Informações sobre a aeronave</h4>
              <span
                style={{
                  visibility: this.state.loading ? "visible" : "hidden",
                }}
              >
                <LinearProgress />
                <br />
              </span>
              <label>Matrícula</label>
              <input
                type="text"
                name="nome"
                id="registrationCode"
                placeholder="PP-AER"
                value={this.state.registration_code || ""}
                onChange={(event) => this.handleChengeRegistrationCode(event)}
              />
              <input
                type="button"
                id="searchAircraft"
                value="Pesquisar"
                onClick={this.handleSearchAircraft}
              />
              <div className="details">
                {Object.keys(this.state.aircraft).length > 0 ? (
                  <>
                    <span>
                      <strong>Matrícula:</strong>{" "}
                      {this.state.aircraft.registration_code}
                    </span>
                    <br />
                    <span>
                      <strong>Fabricante:</strong>{" "}
                      {this.state.aircraft.manufacturer}
                    </span>
                    <br />
                    <span>
                      <strong>Modelo:</strong> {this.state.aircraft.model}
                    </span>
                    <br />
                    <br />
                    <div className="validateInformations">
                      As informações acima estão corretas?
                      <br />
                      <span
                        className={!this.state.incorrectInfos && "clicked"}
                        onClick={() => this.setState({ incorrectInfos: false })}
                      >
                        ✔️ Sim
                      </span>
                      <span
                        className={
                          "n " + (this.state.incorrectInfos && "clicked")
                        }
                        onClick={() =>
                          this.setState({
                            incorrectInfos: true,
                            incorrectInfosDescriptions: "",
                          })
                        }
                      >
                        ❌ Não
                      </span>
                      {this.state.incorrectInfos && (
                        <div>
                          <textarea
                            rows="2"
                            cols="50"
                            placeholder="Descreva quais informações estão incorretas"
                            value={this.state.incorrectInfosDescriptions || ""}
                            onChange={(event) => {
                              this.setState({
                                incorrectInfosDescriptions: event.target.value,
                              });
                            }}
                          ></textarea>
                        </div>
                      )}
                    </div>
                  </>
                ) : this.state.search ? (
                  <>
                    <div>
                      <Alert severity={"info"} variant="filled">
                        A matrícula pesquisada ainda não está no site, mas você
                        pode enviar a foto mesmo assim.
                      </Alert>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="buttonAlign">
                <input
                  type="button"
                  id="sendPhoto"
                  className={this.state.baseUrlPhoto ? "" : "disabled"}
                  value="Enviar Foto 📤"
                  onClick={
                    this.state.baseUrlPhoto ? this.sendPhoto.bind(this) : null
                  }
                />
              </div>
            </div>
          </div>
        </CenterContainer>
      </>
    );
  }
}
