import React, { Component } from "react";
import LeftContainer from "../../components/LeftContainer";
import axios from "axios";
import { Link } from "react-router-dom";
import { getURL, getPathName } from "../../assets/scripts/Main";
import WaitingLoad from "../../components/WaitingLoad";

export default class Left extends Component {
  state = {
    items: [],
  };
  componentDidMount() {
    axios
      .get(getURL("API") + "/aircraft_ranking", {
        params: { postId: getPathName(3) },
      })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ items: data });
      });
  }
  render() {
    return (
      <LeftContainer>
        {this.state.items.length > 0 ? (
          <div className="defaultBox">
            <h4>Mais Visitados</h4>
            {this.state.items.map((item) => (
              <Link
                to={
                  "aeronave/" + item.aircraft_id + "/" + item.registration_code
                }
                key={`mons${item.aircraft_id}`}
              >
                <div className="aircraftRanking">
                  <div
                    className="folder"
                    style={
                      item.url_thumb
                        ? {
                            backgroundImage: `url(${item.url_thumb})`,
                          }
                        : {
                            backgroundImage: `url(./assets/img/default_images/aircraft.png)`,
                          }
                    }
                  ></div>

                  <div className="text">
                    <span className="title">{item.registration_code}</span>
                    <span className="description">
                      {item.manufacturer + " " + item.model}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <>
            <WaitingLoad width="100%" height="100px" />
            <WaitingLoad width="100%" height="100px" />
            <WaitingLoad width="100%" height="100px" />
          </>
        )}
        <div className="about">
          <Link to="/termos-de-uso" target="_Blank">
            {" "}
            Termos de uso
          </Link>{" "}
          -
          <Link to="/sobre" target="_Blank">
            {" "}
            Sobre nós
          </Link>
          <br />
          <br />
          <div
            style={{
              backgroundImage: `url(./assets/img/icons/br-mg.png)`,
            }}
          >
            Aeronaves.Info &copy; 2024&nbsp;&nbsp;|
          </div>
        </div>
      </LeftContainer>
    );
  }
}
